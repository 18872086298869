/* You can add global styles to this file, and also import other style files */
@import "node_modules/ngx-toastr/toastr";


html body {
  font-family: 'Nunito', sans-serif !important;
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
  margin-bottom: 0;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
  max-width: 1440px;
  overflow: hidden;
}

// Sidebar
.sidebar {
  margin: 0;
  padding: 0 15px;
  width: 250px;
  background-color: #fff;
  position: fixed;
  overflow: auto;
  height: 100%;
  left: 0;
  top: 0;
  border-right: 1px solid rgba(226, 232, 240, 0.50);
}

.sidebar a {
  color: #64748B;
  padding: 15px 5px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  margin: 5px 0 15px;
  border-radius: 12px;
  border: 1px solid #E2E8F0;
  height: 64px;
}

.sidebar a.active, .sidebar a:hover {
  // background-color: #0094D9;
  // background-color: #3ec3e8;
  background-color: #0094D9;
  color: #ffffff;
  border-color: #0094D9;
  // border-color: #3ec3e8;
  // border-color: #0094D9;
}

// .sidebar a img {
//   padding-right: 12px;
//   width: 30px;
// }
.logo img {
  width: 111px;
  margin-top: 10px;
}

span.sidebar-icon {
  width: 40px;
  display: flex;
  height: 40px;
  align-items: center;
  position: relative;
  justify-content: center;
}
div.content {
  padding: 30px;
  margin-top: 80px;
  width: calc(100% - 250px);
  float: right;
  // background: #FEFBF8;
  // background: #98D5F7;
  height: calc(100vh - 80px);
  overflow: auto;
}

@media (max-width: 1367px) {
  // .sidebar a {
  //   padding: 6px 30px;
  //   font-size: 13px;
  // }
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content,
  .header {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}

/* ===========================================
Start modal common css
============================================*/
.modal.fade {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.modal-header {
  border-bottom: 0 !important;
  z-index: 1;
}

.modal-dialog {
  min-width: 400px;
  max-width: 600px;
  box-shadow: 0 50px 99px rgba(0, 0, 0, 0.1);
}

.modal-content {
  // height: 380px;
  border-radius: 17px;
}

.modal-body {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-top: -3rem;

  p {
    font-size: 20px;
    line-height: 34px;
    font-weight: 500;
  }
}

.modal-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 30px;

  .btn {
    width: 130px;
    height: 50px;
    border-radius: 11px;
    color: #fff;
  }

  .btn-cancel {
    background: #9399b1;
  }

  .btn-confirm {
    // background: #f7591f;
    // background: #FE4444;
    background: #0094D9 !important;
  }

  .btn-blue {
    // background: #f7591f;
    background: #FE4444;
  }
}

.btn-blue {
  // background: #f7591f!important;
  background: #0094D9 !important;
  border: solid 1px #0094D9 !important;
}

.modal-btn.text-center {
  justify-content: center;
}

.modal-header .close {
  margin: 0 0rem -1rem auto;
  border: 1px solid #dedede;
  border-radius: 50%;
  font-weight: 300;
  font-size: 35px;
  color: #1a1818;
  padding: 0;
  width: 40px;
  height: 40px;
}

// End modal common css

/*==============================================
 Start Careers and Events page common css
 ==============================================*/
@media (max-width: 1367px) {
  .tab-content,
  table.table.mt-2 {
    width: 1025px;
  }
}

.skill-tabs {
  img.title-thumb {
    width: 44px;
    height: 40px;
    border-radius: 11px;
    object-fit: cover;
    margin-right: 10px;
  }

  .table {
    thead {
      th {
        border-bottom: 1px solid #efedee;
        font-size: 16px;
        font-weight: 700;
        color: #151515;
        padding: 15px;
        vertical-align: middle;
      }
    }

    tr {
      border-bottom: 1px solid var(--bs-gray-200);

      td {
        font-size: 14px;
        font-weight: 500;
        color: #1a1818;
        vertical-align: middle;
        border: 0;
        padding: 5px 15px;

        .action-btn {
          width: 45px;
          border-radius: 12px;
          height: 45px;
          background: var(--bs-gray-100);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            background: var(--bs-gray-200);
          }
        }
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  a.nav-link.active {
    width: 140px;
    height: 40px;
    text-align: center;
    border-radius: 11px;
    background: #346ef6;
    margin-right: 15px;
    color: #fff;
    margin-bottom: 5px;
  }

  a.nav-link {
    width: 140px;
    height: 40px;
    line-height: 25px;
    text-align: center;
    border-radius: 11px;
    background: #f8f7fb;
    margin-right: 15px;
    color: #1a1818;
    margin-bottom: 5px;
  }

  button.btn.btn-yellow {
    font-size: 12px;
    font-weight: 500;
    background: #fff8ec;
    border-radius: 11px;
    height: 34px;
    color: #ff9f0a;
  }

  button.btn.btn-active {
    font-size: 12px;
    font-weight: 500;
    background: #ebfff0;
    border-radius: 11px;
    height: 34px;
    color: #34c759;
    width: 80px;
  }

  button.btn.btn-expired {
    font-size: 12px;
    font-weight: 500;
    background: #ffebeb;
    border-radius: 11px;
    height: 34px;
    color: #f3728b;
    width: 80px;
  }

  tr th:last-child {
    width: 120px;
  }

  td span:nth-child(2) {
    margin-left: 20px;
    margin-right: 20px;
  }

  td span img {
    cursor: pointer;
  }

  .skill-tab-right {
    position: relative;
  }

  .btn.btn-create {
    position: absolute;
    right: 0;
    background: #1a1818;
    color: #fff;
    border-radius: 11px;
    height: 40px;
    display: flex;
    align-items: center;

    img {
      margin-right: 5px;
    }
  }
}

// End Careers and Events common css

/*============================================
Start Views header
============================================*/
.views-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn {
    height: 40px;
    border-radius: 11px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    min-width: 120px;
    max-width: 205px;
    text-align: center;
  }

  .btn.btn-blue {
    // background: #f7591f;
    // background: #346ef6;
    background: #FE4444;
    color: #fff;
  }

  .btn.btn-red {
    background: #ffebeb;
    color: #f3728b;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
  }
}

h1 {
  margin-bottom: 0;
}

// End Views header

/* ====================================
Breadcrumb css
======================================*/
.breadcrmb {
  span.breadcrumb-link {
    font-size: 16px;
    color: #9399b1;
    cursor: pointer;
  }

  img {
    padding: 0 7px;
  }
}

// end breadcrumb css

// Paginatoin
.page-link {
  border: 0;
}

.page-item.active .page-link {
  // background-color: #f7591f;
  background: #0094D9;
  border-radius: 7px;
  width: 35px;
  height: 35px;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 16px;
  color: #fff;
  text-align: center;
}

.page-item .page-link {
  background-color: #fff;
  border-radius: 7px;
  width: 35px;
  height: 35px;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 16px;
  color: #1a1818;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.pagination-prev.page-item a,
.pagination-next.page-item a {
  font-size: 35px;
  line-height: 17px;
}

.pagination-prev.page-item .page-link,
.pagination-next.page-item .page-link {
  background-color: #f8f7fb;
}

// Search form css
.search-form {
  position: absolute;
  top: 24px;
  right: 460px;

  .form-group {
    position: relative;
    margin-bottom: 0;

    img {
      position: absolute;
      left: 15px;
      top: 19px;
    }
  }

  .form-control {
    height: 60px;
    border-radius: 11px;
    background: #f8f7fb;
    width: 350px;
    border: 0;
    padding-left: 50px;
    font-size: 18px;
    font-weight: 500;
    color: #9399b1;
  }
}

// CkeEditor css
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.ck.ck-editor__main > .ck-editor__editable {
  border: 0;
  border-color: transparent;
  background: #f8f7fb;
  margin-top: 7px;
  min-height: 60px;
  border-radius: 8px;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  box-shadow: none;
  border: 8px;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border: 0;
}

select option[data-default] {
  color: #888;
}

p,
h1,
h2,
h3,
h4, h5, h6 {
  word-wrap: break-word !important;
}

.breadcrmb {
  font-size: 16px !important;
}

// Responsivness
@media (min-width: 1680px) and (max-width: 3840px) {
  .header {
    border-right: 1px solid #efedee;
  }
  div.content {
    border-right: 1px solid #efedee;
    border-bottom: 1px solid #efedee;
  }
  .sidebar {
    border-bottom: 1px solid #efedee;
    border-left: 1px solid #efedee;
  }
}

@media (max-width: 1281px) {
  .header {
    max-width: 1015px !important;
  }
  div.content {
    max-width: 995px !important;
  }
  .tab-content, table.table.mt-2 {
    max-width: 955px;
  }
  .total-count {
    max-width: 230px !important;
  }
  .news-list .news-card {
    max-width: 33% !important;
    margin-right: 5px !important;
  }
  .partner-lists .p-list {
    margin-right: 18px !important;
  }
  .news-list .news-card span.new-date {
    padding-right: 5px !important;
  }
}

// Time picker
.timepicker {
  outline: none !important;

  .timepicker__header {
    background-color: #346ef6 !important;
  }

  .clock-face__clock-hand {
    background-color: #346ef6 !important;

    &::after {
      background-color: #346ef6 !important;
    }

    &::before {
      background-color: #346ef6 !important;
    }
  }

  .clock-face__number {
    > span.active {
      background-color: #346ef6 !important;
    }
  }

  button:not(.timepicker-dial__item) {
    color: #346ef6 !important;
  }

}

.btn.disabled, .btn:disabled {
  opacity: 0.15;
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('./assets/images/loader.svg') 50% 50% no-repeat rgba(0, 0, 0, 0.7);
  background-size: 4%;
}

.breadcrmb span.breadcrumb-link:hover {
  text-decoration: underline;
}
